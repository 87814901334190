import { captureException } from '@sentry/nextjs';

import config from '@config';
import {
  MinifiedServerTaskState,
  TerminalStrategy,
  ArduinoCompileDto,
  ArduinoLogType,
  PcbType,
} from '@projectTypes/Terminal';

import { connect, connectSerial } from '@lib/AdafruitWebSerialEspTool';
import { ESPLoader } from '@lib/AdafruitWebSerialEspTool/esp_loader';
import { baudRates } from '@lib/AdafruitWebSerialEspTool/const';
import delay from '@helpers/delay';

import ApiService from './_private/ApiService';

export default class ArduinoTerminalStrategy extends TerminalStrategy {
  apiService: ApiService;
  isCompiling: boolean;
  loader?: ESPLoader;
  loaderStub?: ESPLoader;
  device?: any;

  constructor() {
    super();
    this.apiService = new ApiService(
      config.arduinoBuilderHost,
      config.arduinoBuilderPath,
    );
  }

  async compileProject(
    projectId: string,
    code: string,
    ota = false,
    pcbType = PcbType.v1,
    skipValidation = false,
  ) {
    this.isCompiling = true;
    if (!skipValidation) {
      try {
        await this.apiService.validateCode(code, pcbType);
      } catch (e) {
        throw JSON.stringify(e);
      }
    }
    const compileData = await this.apiService.compileProject(
      projectId,
      code,
      ota,
      pcbType,
    );
    const data = await new Promise<ArduinoCompileDto>((resolve, reject) => {
      let lastLogId = -1;
      const writeLogs = (logJson: string) => {
        const logs = JSON.parse(logJson) as {
          id: number;
          type: ArduinoLogType;
          data: string;
        }[];
        logs.forEach((log) => {
          if (log.id <= lastLogId) {
            return;
          }
          lastLogId = log.id;
          if (log.type === ArduinoLogType.stdout) {
            this.answerMessage({ text: log.data });
          } else if (log.type === ArduinoLogType.stderr) {
            this.answerMessage({ error: log.data });
          }
        });
      };
      const fetchCompileData = async () => {
        if (!this.isCompiling) {
          reject(new Error('canceled'));
        }
        const compileLogs = await this.apiService.getCompileLogs(
          compileData.id,
        );
        switch (compileLogs.status) {
          case MinifiedServerTaskState.queued:
          case MinifiedServerTaskState.running:
            writeLogs(compileLogs.logs);
            return setTimeout(fetchCompileData, 750);
          case MinifiedServerTaskState.done:
            this.isCompiling = false;
            writeLogs(compileLogs.logs);
            return resolve(compileLogs);
          case MinifiedServerTaskState.error:
            writeLogs(compileLogs.logs);
            this.answerMessage({ error: compileLogs.error || 'error' });
            this.isCompiling = false;
            return reject('Something went wrong! Try again.');
          default:
            this.isCompiling = false;
            this.answerMessage({ error: compileLogs.error || 'error' });
            return reject('Something went wrong! Try again.');
        }
      };
      fetchCompileData();
    });
    console.log({ data });
    return data;
  }

  setToken(token: string) {
    this.apiService.setToken(token);
  }

  async setupEspDevice(onDisconnect?: () => void) {
    const device = await connectSerial(
      (message) => this.answerMessage({ text: message, join: true }),
      onDisconnect,
    );
    this.device = device;
    return this.device;
  }

  async getEspLoaderStub() {
    try {
      const loader = await connect({
        log: (msg: string) => {
          // eslint-disable-next-line no-console
          console.log(msg);
          this.answerMessage({ text: msg });
        },
        debug: (msg: string) => {
          // eslint-disable-next-line no-console
          console.log(msg);
          this.answerMessage({ text: msg });
        },
        error: (msg: string) => {
          // eslint-disable-next-line no-console
          console.log(msg);
          this.answerMessage({ error: msg });
        },
      });
      await loader.initialize();
      const loaderStub = await loader.runStub();
      loaderStub.setBaudrate(baudRates[7]);
      this.loader = loader;
      this.loaderStub = loaderStub;
      this.answerMessage({ text: `Connected to ${loader.chipName}` });
      /* this.loaderStub.addEventListener('disconnect', () => {
        this.onEspDeviceDisconnect();
        this.answerMessage({ error: `Device disconnected` });
      }); */
    } catch (e) {
      console.log(e);
      captureException(e);
      this.answerMessage({ error: e ? e.message : e });
      await this.disconnectEspDevice();
    }
    return this.loaderStub;
  }

  async disconnectEspDevice() {
    if (this.loader) {
      await this.loader.disconnect();
      this.loader = null;
      this.loaderStub = null;
    }
  }

  async flashEsp({
    projectId,
    hardReset,
    version,
  }: {
    projectId?: string;
    hardReset?: boolean;
    version?: PcbType;
  }) {
    try {
      let files;
      if (!hardReset) {
        const [data1, data2] = await Promise.all([
          this.apiService.getBinary(projectId),
          this.apiService.getStatic(),
        ]);
        const newData1 = this.transformData(data1);
        const newData2 = this.transformData(data2);
        files = [newData1, newData2]
          .reduce((obj, curr) => [...obj, ...curr], [])
          .sort((f1, f2) => f1.address - f2.address);
      } else {
        files = [this.transformData(await this.apiService.getOta(version))]
          .reduce((obj, curr) => [...obj, ...curr], [])
          .sort((f1, f2) => f1.address - f2.address);
      }
      const loaderStub = await this.getEspLoaderStub();
      if (loaderStub) {
        // eslint-disable-next-line
        await delay(10000);

        for (let file of files) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await loaderStub.flashData(
              file.content,
              (bytesWritten) => {
                const progress = `${
                  Math.floor((bytesWritten / file.content.byteLength) * 1000) /
                  10
                }%`;

                // eslint-disable-next-line no-console
                console.log(progress);
                this.answerMessage({ text: `${file.file} ${progress}` });
              },
              file.address,
            );

            // eslint-disable-next-line no-await-in-loop
            await delay(100);
          } catch (e) {
            // TODO: Handle this SENTRY
            console.log(e);
            captureException(e);
          }
        }
        await loaderStub.hardReset();
      }
    } catch (e) {
      console.log(e);
      this.answerMessage({ error: `Try again.` });
    }
  }

  onEspDeviceDisconnect() {
    this.loaderStub = null;
    this.loader = null;
  }

  registerMessageReceiver() {
    return super.registerMessageReceiver();
  }

  async requestPort() {
    // await requestPort();
  }

  private transformData(data) {
    return Object.keys(data).map((key) => {
      const binary = atob(data[key].content);

      const bytes = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }

      return { ...data[key], content: bytes.buffer };
    });
  }
}
